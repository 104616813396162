:root {
  --clr-bg-main: rgb(250, 250, 250);
  --clr-bg-overtop: white;
  --clr-text-main: black;
  --clr-text-offset: rgb(55, 55, 55);
  --width-project-container: 800px;
  --height-project-container: 600px;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--clr-bg-main);
  font-family: Verdana, sans-serif;
}

.summary-container {
  margin: 75px 0 0 0;
  width: var(--width-project-container);
  padding-right: 50px;
}

.summary-header {
  text-align: right;
  font-size: 3em;
  font-weight: bold;
}

.summary-subheader {
  text-align: right;
  font-size: 1.4em;
  font-weight: bold;
}

.summary-text {
  margin-top: 10px;;
  text-align: center;
  padding-left: 50px;
}

.project-container {
  margin: 40px 0;
}

.project-title {
  text-align: left;
  padding-left: 20px;
  color: var(--clr-text-offset);
  font-family: "Lucida Console", "Courier New", monospace;
}

.technology-list {
  display: flex;
  flex-direction: row;
  justify-content: left;

}

.project-show {
  width: var(--width-project-container);
  height: var(--height-project-container);
}

.project-image {
  width: 100%;
}

.my-info {
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  padding: 10px;
  text-align: left;
  border-right: solid 1px;
  border-bottom: solid 1px;
  border-bottom-right-radius: 60px;
  background: var(--clr-bg-overtop);
}

.hover-space {
  position: absolute;
}

.my-name {
  font-size: 32px;
}

.contact-info {
  font-size: small;
}

.tech-hover {
  position: relative;
  z-index: 2;
  color: var(--clr-text-offset);
  background-color: var(--clr-bg-overtop);
  width: calc(var(--width-project-container) * (2 / 3));
  border: solid var(--clr-text-offset) 1px;
  border-radius: 7px;
  padding: 4px;
}

.technology-show {
  position: relative;
  margin: 0 5px;
}

.project-description {
  margin-top: 20px;
  text-align: left;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.project-description a {
  margin-top: 10px;
  width: fit-content;
}

@media only screen and (max-width: 820px){
  :root {
    --width-project-container: 600px;
    --height-project-container: 450px;
  }
  .summary-subheader {
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 620px){
  :root {
    --width-project-container: 500px;
    --height-project-container: 375px;
  }
  .tech-hover {
    width: calc(var(--width-project-container) / 2);
    transform: translateX(-13px);
  }
  .summary-subheader {
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 520px){
  :root {
    --width-project-container: 400px;
    --height-project-container: 300px;
  }
  .tech-hover {
    width: calc(var(--width-project-container) / 2);
    transform: translateX(-35px);
  }
  .summary-header {
    font-size: 2.5em;
  }
  .summary-subheader {
    font-size: .9em;
  }
}